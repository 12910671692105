<template>
  <v-textarea
    v-model="value"
    :label="`${$t(label)}${(required) ? ' *' : ''}`"
    :color="color"
    :rules="rules"
    :prepend-icon="icon"
    :readonly="fieldReadonly"
  >
    <v-tooltip slot="append-outer" bottom v-if="$t(description)">
      <template v-slot:activator="{ on }">
        <v-icon slot="activator" v-on="on">mdi-help-circle</v-icon>
      </template>
      <span>{{ $t(description) }}</span>
    </v-tooltip>
  </v-textarea>
</template>

<script>
import baseTextInput from '@/mixins/baseTextInput';

export default {
  mixins: [
    baseTextInput,
  ],
  props: {
    label: {
      type: String,
      default: 'fields.textarea',
    },
    description: {
      type: String,
      default: 'fields.textareaTooltip',
    },
  },
};
</script>
